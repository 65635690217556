<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ data.trolley_code }} Trolley</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-5">Pack code</div>
            <div class="col-5">Size</div>
          </div>
          <div
            class="row mb-1 align-items-center"
            v-for="(pack, i) in data.trolleyPacks"
            :key="pack.id"
          >
            <div class="col-5 bold-16">{{ pack.pack_code }}</div>
            <div class="col-5">{{ pack.name }}</div>
            <div class="col-2">
              <button
                class="btn btn-sm btn-outline-danger"
                v-on:click="deleteRow(pack.id, i)"
              >
                <BaseIcon name="trash" />
              </button>
            </div>
          </div>
          <h4 class="mt-4">Add new</h4>
          <div class="row mt-2">
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                placeholder="Pack code"
                v-model="newRow.pack_code"
              />
            </div>
            <div class="col-6">
              <select
                class="form-control bg-white"
                v-model="newRow.trolley_pack_size_id"
              >
                <option v-for="size in sizes" :key="size.id" :value="size.id">
                  {{ size.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="addAsset"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("finance.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import BaseIcon from "../icons/BaseIcon.vue";
const bootstrap = require("bootstrap");

export default {
  name: "TrolleyBox",
  props: {
    modelValue: Object,
    sizes: Array,
  },
  emits: ["update:modelValue", "add"],
  data() {
    return {
      myModal: undefined,
      success: false,
      loadAddBtn: false,
      data: [],
      newRow: {},
    };
  },
  watch: {
    modelValue() {
      if (this.modelValue > 0) {
        this.load();
      }
    },
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
    load() {
      http.fetch("/trolleys/" + this.modelValue).then((data) => {
        this.data = data;
        this.newRow.pack_code = data.trolleyPacks.length + 1;
      });
    },
    deleteRow(id, i) {
      http.fetch("/trolleys/pack/" + id, {}, true, "DELETE");
      this.data.trolleyPacks.splice(i, 1);
    },
    addAsset() {
      this.loadAddBtn = true;
      this.newRow.trolley_id = this.modelValue;
      http
        .fetch("/trolleys/pack/add", this.newRow, true)
        .then((data) => {
          data.name = this.sizes.find(
            (e) => e.id == this.newRow.trolley_pack_size_id
          )?.name;
          this.data.trolleyPacks.push(data);
          this.loadAddBtn = false;
          this.newRow.pack_code += 1;
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
  },
  components: { BaseIcon },
};
</script>
